import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import fibooShapeMono from '../images/fiboo_icon_shape.svg'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");

const ContactUsForm = () => {

  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [company, setCompany] = useState(null);
  const [subject, setSubject] = useState("Toplantı Talebi");
  const [message, setMessage] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (ev) => {
    setLoading(true);
    let fields = {
      NAME: name,
      EMAIL: email,
      PHONE:phone,
      COMPANY: company,
      SUBJECT: subject,
      MESSAGE: message
    }

    // let group = params.get('group');
    // if(group){
    //   fields['group[24473]'] = group;
    // }
    addToMailchimp(email, fields).then( res => {
      setLoading(false);
      $('#wf-form-Contact-Form').hide();
      $('.success-message').show();
    }).catch(()=>{
      setLoading(false);
      $('#wf-form-Contact-Form').hide();
      $('.w-form-fail').show();
    });

    ev.preventDefault();
    return false;
  }

  useEffect(() => {
    // Fade Up 5 (Footer blocks)
    var fadeUp5 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp5
      .add({
        targets: '.footer-block',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 900,
        delay: (el, i) => 50 + 80 * i
      });


    // Fade Up 5 (Product Preview)
    var fadeUp6 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp6
      .add({
        targets: '.field-label',
        translateY: [25, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 50 + 80 * i
      });



    // Play animation when scrolled into view
    $('#inview5').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp5.play();
        fadeUp5.restart();
      } else {
      }
    });

    // Play animation when scrolled into view
    $('#inview6').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp6.play();
        fadeUp6.restart();
      } else {
      }
    });
  }, []);

  return (<section className="call-to-action ">
    <div className="top-line-block">
      <div className="horizontal-top-line _20-white"></div>
      <h4 className="top-line-heading">İletişim</h4>
    </div>
    <div id="inview6" className="cta-container">
      <div className="cta-title-container">
        <h1 data-w-id="49e6a067-b804-d254-16de-9d620d717fc8" className="cta-title">Sorularınız ve talepleriniz için</h1>
        <p className="hero-paragraph simple-fade-in cta-summary">Ürünler hakkında bilgi isteyebilir, demo talebinde bulunabilirsiniz. Uzmanlarımız sizi ihtiyacınıza göre en doğru çözüme yönlendirecektir.</p>
      </div>
      <div className="cta-form-container">
        <div className="w-form">
          <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form" className="form" onSubmit={onSubmit}>
            <div className="div-block-13">
              <div className="form-sub-container _1">
                <label htmlFor="Name-3" className="field-label" >Ad Soyad</label>
                <input type="text" className="text-field w-input" autoComplete="off" maxLength="256" name="Name" data-name="Name" placeholder="" id="Name-3" required="" onChange={(ev) => setName(ev.target.value)} disabled={isLoading} />
                <label htmlFor="Phone-3" className="field-label" >Cep Telefonu</label>
                <input type="tel" className="text-field w-input" maxLength="256" name="Phone" data-name="" placeholder="" id="Phone-3" onChange={(ev) => setPhone(ev.target.value)} disabled={isLoading}/>
              </div>
              <div className="form-sub-container">
                <label htmlFor="Email-4" className="field-label" >E-Posta</label>
                <input type="email" className="text-field w-input" autoComplete="off" maxLength="256" name="Email" data-name="Email" placeholder="" id="Email-4" required="" onChange={(ev) => setEmail(ev.target.value)} disabled={isLoading}/>
                <label htmlFor="Subject-3" className="field-label" >Konu</label>
                <select id="Subject-3" name="Subject" data-name="Subject" required="" className="select-field w-select" onChange={(ev) => setSubject(ev.target.value)} disabled={isLoading}>
                  <option value="Toplantı Talebi">Toplantı Talebi</option>
                  <option value="Demo Talebi">Demo Talebi</option>
                  <option value="Danışmanlık">Danışmanlık</option>
                  <option value="Diğer">Diğer</option>
                </select>
              </div>
            </div>
            <label htmlFor="Message-3" className="field-label" >Mesaj</label>
            <textarea placeholder="" maxLength="5000" id="Message-3" name="Message" required="" data-name="Message" className="text-field w-input" onChange={(ev) => setMessage(ev.target.value)} disabled={isLoading}></textarea>
            <input type="submit" value="Gönder" data-wait="Please wait..." disabled={isLoading} className="btn big cta-form w-button" />
          </form>
          <div className="success-message w-form-done">
            <div className="success-text">Teşekkürler! <br />Mesajınız bize ulaştı.</div>
            <div className="success-sub">En kısa sürede geri dönüş sağlayacağız.</div>
          </div>
          <div className="w-form-fail">
            <div>Çok üzgünüz, mail gönderilirken hata alındı. Lütfen daha sonra tekrar deneyiniz.</div>
          </div>
        </div>
      </div>
      <img src={fibooShapeMono} loading="lazy" alt="" className="cta-shape" />
    </div>
  </section>
  )
}

export default ContactUsForm
