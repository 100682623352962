import React, { useEffect } from 'react'
import Layout from '../components/layout.tr'

import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import ClientCard from '../components/clientCard'
import Select from '../components/select'
import PageHeader from '../components/pageHeader';
import ContactUsForm from '../components/contact-us.tr';
import networking from "../images/networking.svg"

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");


export default () => {

  useEffect(() => {
    // Fade Up 3 (Client boxes)
    var fadeUp3 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp3
      .add({
        targets: '.client-con',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1600,
        delay: (el, i) => 50 + 80 * i
      });


    // Play animation when scrolled into view
    $('#inview3').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp3.play();
        fadeUp3.restart();
      } else {
      }
    });

  });

  return (
    <Layout currentPath="/tr/referanslar/" light={true} fullPage={true}>

      <SEO title="Referanslar" />
      <div className="header-hero">
        <div className="div-block-21">
          <img src={networking} loading="lazy" alt="" className="img-rocket" />
          <h2 className="centered-h2">Referanslarımız</h2>
        </div>
      </div>
      <section id="inview3" className="client-testimonials ">
        <div className="clients-container">
          <div id="inview3" className="w-layout-grid grid">
            <div className="client-con _1" >
              <div className="client-row-logo _1"></div>
            </div>
            <div className="client-con _2" >
              <div className="client-row-logo _2"></div>
            </div>
            <div className="client-con _3" >
              <div className="client-row-logo _3"></div>
            </div>
            <div className="client-con _4" >
              <div className="client-row-logo _4"></div>
            </div>
            <div className="client-con _5" >
              <div className="client-row-logo _5"></div>
            </div>
            <div className="client-con _6" >
              <div className="client-row-logo _6"></div>
            </div>
            <div className="client-con _7" >
              <div className="client-row-logo _7"></div>
            </div>
            <div className="client-con _8" >
              <div className="client-row-logo _8"></div>
            </div>
            <div className="client-con _9" >
              <div className="client-row-logo _9"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="spacer"></div>
      <ContactUsForm />
    </Layout>
  );
}